import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { Auth0Provider } from '@auth0/auth0-react';
import { auth0Audience, auth0ClientId, auth0Domain } from './config/variable';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();
ReactDOM.render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <SidebarProvider>
        <Auth0Provider
          domain={auth0Domain}
          clientId={auth0ClientId}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: auth0Audience
          }}
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Auth0Provider>
      </SidebarProvider>
    </QueryClientProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
